import { ADD_EMAIL_ENDPOINT } from './config';
import { AddEmailResponse } from './types';

export const addEmail = async (hash, email, awaitsResult = false): Promise<AddEmailResponse> => {
	const apiUrl = `${ADD_EMAIL_ENDPOINT}?hash=${encodeURIComponent(hash)}&email=${encodeURIComponent(email)}${
		awaitsResult ? '&awaitsResult=1' : ''
	}`;

	const response = await fetch(apiUrl, { method: 'POST' });
	const data = await response.json();

	if (!response.ok) {
		const values = {};
		let messageId = 'responseError';

		if ('alreadyListed' in data && data.alreadyListed) {
			messageId = 'email.alreadyListed';
		}

		throw { messageId, values };
	}

	return data;
};

import React, { FC, useEffect, useState } from 'react';
import { RowMain } from '../../layout/RowMain';
import { EmailForm } from '../../organisms/EmailForm';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { IncompleteEmailForm } from '../../organisms/IncompleteEmailForm';

export type Props = {
	complete: boolean;
	hash: boolean;
};

export const BannerForm: FC<Props> = ({ complete, hash }) => {
	const textComplete = complete ? 'complete' : 'incomplete';
	const [showEmailForm, setShowEmailForm] = useState(complete);

	useEffect(() => {
		setTimeout(() => setShowEmailForm(true), 5000);
	}, []);

	if (showEmailForm || complete) {
		return (
			<section id="email-report" className={`s-banner${!complete ? ' s-banner--sm' : ''}`}>
				<RowMain>
					<div className="s-banner__inner u-bg-color-blue-lightest">
						<h2 className="s-banner__title u-color-blue u-text-center">
							<span className="u-color-blue-light">
								<FormattedMessage id={`email.headline.${textComplete}`} />
							</span>
							<small className="u-block">
								<FormattedMessage id={`email.annotation.${textComplete}`} />
							</small>
						</h2>
						{complete ? <EmailForm /> : <IncompleteEmailForm hash={hash} />}
						{complete && (
							<figure className="u-text-center u-mb0">
								<img
									data-src="/img/aktualizace.svg"
									width="628"
									height="317"
									alt="Aktualizace e-mailem"
									className="lazyload"
								/>
								<noscript>
									<img src="/img/aktualizace.svg" alt="Aktualizace e-mailem" />
								</noscript>
							</figure>
						)}
					</div>
				</RowMain>
			</section>
		);
	}

	return <></>;
};

BannerForm.displayName = 'BannerForm';

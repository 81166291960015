import React, { FC, memo } from 'react';

export type Props = {
	for?: string;
};

export const Label: FC<Props> = memo(props => {
	const { for: htmlFor, children, className } = props;

	return (
		<label htmlFor={htmlFor} className={`inp-label${className ? ` ${className}` : ''}`}>
			{children}
		</label>
	);
});

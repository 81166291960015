import React, { FC, InputHTMLAttributes, forwardRef, Ref } from 'react';

type Props = InputHTMLAttributes<HTMLInputElement> & {
	ref?: Ref<HTMLInputElement>;
};

export const Input: FC<Props> = forwardRef<HTMLInputElement, Props>(({ children, ...attrs }, ref) => {
	const { wrapClassName, ...rest } = attrs;

	return (
		<span className={`inp-fix${wrapClassName ? ` ${wrapClassName}` : ''}`}>
			<input {...rest} className={`inp-text${rest.className ? ` ${rest.className}` : ''}`} ref={ref} />
			{children}
		</span>
	);
});

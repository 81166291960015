import React from 'react';
import { TestResult } from '../components/organisms/TestResult';
import { Layout } from '../components/ui/Layout';
import { getHashFromPath } from '../utils/getHashFromPath';
import loadable from '@loadable/component';

const Helmet = loadable(() => import('react-helmet'));
const Header = loadable(() => import('../components/layout/Header'));
const Banner = loadable(() => import('../components/ui/banner/Banner'));

export default ({ location: { pathname, state } }) => {
	const hash = getHashFromPath(pathname);

	return (
		<Layout>
			<Helmet>
				<title>PageSpeed.cz - Výsledek testu</title>
			</Helmet>
			<Header />
			<TestResult hash={hash} navigation={state && state.form} />
			<Banner />
		</Layout>
	);
};

import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { FC } from 'react';
import { Dial } from '../molecules/Dial';
import { FormattedPercent } from '../molecules/FormattedPercent';
import { FormattedSeconds } from '../molecules/FormattedSeconds';
import { ScoreTooltip } from '../molecules/ScoreTooltip';
import { FcpTooltip } from '../molecules/FcpTooltip';
import { FidTooltip } from '../molecules/FidTooltip';
import { Measurement } from '../../api/types';

import { getCategoryColor } from '../../utils/getCategoryColor';

interface Props {
	result: Array<Measurement>;
}

export const Dials: FC<Props> = ({ result }) => {
	const lastResult = result[0];
	const hasLoadingExperience = lastResult && lastResult.loadingExperience && lastResult.loadingExperience.metrics;

	return (
		<section className="c-dials">
			{lastResult && lastResult.score && (
				<>
					<Dial
						value={lastResult.score}
						size="big"
						unit="%"
						title={
							<>
								<FormattedMessage id="dial.lps.titleLong" />
								<br /> (<FormattedMessage id="dial.lps.title" />
								): {/*<FormattedPercent value={Number(lastResult.score) * 100} fractionDigits={0} />*/}
							</>
						}
						tooltip={<ScoreTooltip />}
						status={getCategoryColor(lastResult.score)}
					/>
				</>
			)}

			{hasLoadingExperience && (
				<>
					<Dial
						value={lastResult.loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS.percentile}
						size="small"
						unit="s"
						title={
							<>
								<FormattedMessage id="dial.fcp.titleLong" />
								<br /> (<FormattedMessage id="dial.fcp.title" />
								):{' '}
								{/*<FormattedSeconds value={lastResult.loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS.percentile} unit='unit' />*/}
							</>
						}
						tooltip={<FcpTooltip />}
						status={getCategoryColor(
							lastResult.loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS.category
						)}
					/>
					<Dial
						value={lastResult.loadingExperience.metrics.FIRST_INPUT_DELAY_MS.percentile}
						size="small"
						unit="s"
						title={
							<>
								<FormattedMessage id="dial.fid.titleLong" />
								<br /> (<FormattedMessage id="dial.fid.title" />
								):{' '}
								{/* <FormattedSeconds value={lastResult.loadingExperience.metrics.FIRST_INPUT_DELAY_MS.percentile} unit='unit' /> */}
							</>
						}
						tooltip={<FidTooltip />}
						status={getCategoryColor(lastResult.loadingExperience.metrics.FIRST_INPUT_DELAY_MS.category)}
					/>
				</>
			)}
		</section>
	);
};

export type Stat = {
	url: string;
	new: boolean;
};

export type CreateNewTestResponse = {
	hash?: string;
	valid: boolean;
	date?: string;
	urls?: Array<Stat>;
};

export type Measurement = {
	date: string;
	score: number;
	loadingExperience?: LoadingExperience;
	finalUrl?: string;
};

export type LoadingExperience = {
	id: string;
	metrics: {
		FIRST_CONTENTFUL_PAINT_MS: LoadingExperienceMetric;
		FIRST_INPUT_DELAY_MS: LoadingExperienceMetric;
	};
	overall_category: LoadingExperienceCategory;
	initial_url: string;
};

export type LoadingExperienceMetric = {
	percentile: number;
	distributions: Array<LoadingExperienceDistribution>;
	category: LoadingExperienceCategory;
};

export type LoadingExperienceDistribution = {
	min: number;
	max: number;
	proportion: number;
};

export enum LoadingExperienceCategory {
	FAST = 'FAST',
	AVERAGE = 'AVERAGE',
	SLOW = 'SLOW',
	NONE = 'NONE',
}

export type GetTestResultResponse = {
	complete: boolean;
	hash?: string;
	mobile?: {
		[url: string]: Array<Measurement>;
	};
	web?: {
		[url: string]: Array<Measurement>;
	};
};

export type AddEmailResponse = {
	message?: string;
};

export type UnsubscribeResponse = {
	message?: string;
};

export type GetAdminDataResponse = {
	page: number;
	tests?: Array<{
		id: number;
		date: string;
		hash: string;
		urls: Array<{
			id: number;
			url: string;
		}>;
	}>;
};

export type ForceTestResponse = {
	url: string;
};

import React, { FC, memo } from 'react';

export type Props = {};

export const Container: FC<Props> = memo(props => {
	const { children } = props;

	return <div {...props}>{children}</div>;
});

Container.displayName = 'Container';

import React, { FC, memo } from 'react';
import { FormattedNumber } from 'gatsby-plugin-intl';

export type Props = {
	value: number;
	fractionDigits?: number;
};

export const FormattedPercent: FC<Props> = memo(props => {
	const { value, fractionDigits } = props;
	const decimalPlaces =
		typeof fractionDigits === 'number' ? fractionDigits : value === 0 ? 0 : value <= -100 || value >= 100 ? 1 : 2;

	return (
		<FormattedNumber
			value={value / 100}
			minimumFractionDigits={decimalPlaces}
			maximumFractionDigits={decimalPlaces}
			style="percent"
		/>
	);
});

FormattedPercent.displayName = 'FormattedPercent';

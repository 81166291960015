import React, { FC, useLayoutEffect, useRef } from 'react';

interface Props {
	color: string;
	url: string;
	main: boolean;
}

export const Url: FC<Props> = ({ color, url, main }) => {
	const pathRef = useRef<HTMLSpanElement>(null);
	const [protocol, path] = url.split('//');
	// console.log(path);
	// let truncatable = path;
	// let lastWordPart = '';
	// const matches = path.match(/[\/\-_](\w{1,10}\/?)$/);
	// if (matches) {
	// 	lastWordPart = matches[1];
	// 	truncatable = path.slice(0, -lastWordPart.length);
	// }

	// const truncate = () => {
	// 	const pathEl = pathRef.current;
	// 	if (!pathEl) return;

	// 	pathEl.innerHTML = truncatable + lastWordPart;
	// 	const parentBox = pathEl.parentElement.getBoundingClientRect();
	// 	pathEl.parentElement.classList.add('c-url--nowrap');
	// 	const pathBox = pathEl.getBoundingClientRect();
	// 	const maxWidth = parentBox.width + parentBox.x - pathBox.x;
	// 	const diff = maxWidth - pathBox.width;
	// 	if (diff < 0) {
	// 		const characterSize = pathBox.width / pathEl.textContent.length;
	// 		const charactersToRemove = Math.ceil(diff / characterSize) - 10;
	// 		if (Math.abs(charactersToRemove) !== 0) {
	// 			pathEl.innerHTML = `${truncatable.slice(0, charactersToRemove)}&hellip;${lastWordPart}`;
	// 		}
	// 	}
	// 	pathEl.parentElement.classList.remove('c-url--nowrap');
	// };

	// useLayoutEffect(() => {
	// 	truncate();
	// 	window.setTimeout(truncate, 555); // FIXME - dark magic
	// 	window.addEventListener('resize', truncate);
	// 	return () => window.removeEventListener('resize', truncate);
	// }, []);

	const divStyle = {
		color: color,
	};

	return (
		<span className={`c-url${main ? ' c-url--main' : ''}`} style={divStyle}>
			<span className="c-url__icon" />
			<span className="c-url__protocol">{protocol}//</span>
			<span className="c-url__path" ref={pathRef}>
				{path}
			</span>
		</span>
	);
};

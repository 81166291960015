import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { useInterval } from '../../hooks/useInterval';
import { getRandomTip } from '../../utils/getRandomTip';
import { Text } from '../ui/typography/Text';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

export const RandomTip: FC = () => {
	const intl = useIntl();
	const [tip, setTip] = useState(getRandomTip(intl));

	useInterval(() => {
		setTip(getRandomTip(intl));
	}, 10000);

	return (
		<Text className="c-randomTip">
			<em>
				<strong>
					<FormattedMessage id="tips.title" />
				</strong>{' '}
				<FormattedMessage
					id={`${tip}.text`}
					values={{
						link: (
							<OutboundLink href={intl.formatMessage({ id: `${tip}.link.href` })} target="_blank">
								<FormattedMessage id={`${tip}.link.title`} />
							</OutboundLink>
						),
					}}
				/>
			</em>
		</Text>
	);
};

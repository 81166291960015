import Chart from 'chart.js';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import cs from 'moment/locale/cs';
import React, { FC, useEffect, useRef } from 'react';
import { Measurement } from '../../api/types';
import { colorMap } from '../../utils/colorMap';
import { Text } from '../ui/typography/Text';


const locales = {
	cs: cs,
};

interface Props {
	results: {
		[url: string]: Array<Measurement>;
	};
}

export const LineChart: FC<Props> = ({ results }) => {
	const canvasEl = useRef<HTMLCanvasElement>(null);
	const intl = useIntl();

	const dates = [
		...new Set(
			Object.keys(results)
				.map(url => {
					return results[url].map(score => score.date.split('T')[0]);
				})
				.reduce((accumulator, urlDates) => [...accumulator, ...urlDates], [])
				.sort()
		),
	];

	const chartData = {
		datasets: Object.keys(results).map((url, index) => ({
			label: url,
			fill: index === 0,
			order: index,
			backgroundColor: colorMap[index] + '50',
			lineTension: 0,
			borderColor: colorMap[index],
			pointRadius: 3,
			pointHoverRadius: 5,
			spanGaps: true,
			data: dates.map(date => {
				const score = results[url].find(score => score.date.split('T')[0] === date);
				return {
					x: new Date(date),
					y: score ? Number(score.score) : null,
				};
			}),
		})),
	};

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		layout: {
			padding: 5,
		},
		tooltips: {
			position: 'nearest',
			mode: 'index',
			intersect: false,
			callbacks: {
				label: ({ datasetIndex, index }, data) =>
					`${data.datasets[datasetIndex].label}: ${Math.round(
						data.datasets[datasetIndex].data[index].y * 100
					)} %`,
			},
		},
		legend: {
			display: false,
		},
		scales: {
			xAxes: [
				{
					adapters: {
						date: {
							locale: locales[intl.locale],
						},
					},
					display: true,
					type: 'time',
					time: {
						unit: 'month',
						tooltipFormat: 'DD.MM.YYYY',
						displayFormats: {
							month: 'MMMM YYYY',
						},
					},
					gridLines: {
						display: false,
					},
				},
			],
			yAxes: [
				{
					ticks: {
						min: 0,
						max: 1,
						display: false,
					},
					gridLines: {
						drawTicks: false,
						drawBorder: false,
						borderDash: [3, 1],
						color: '#d2d2d2',
					},
				},
			],
		},
	};

	useEffect(() => {
		const chart = new Chart(canvasEl.current, {
			type: 'line',
			data: chartData,
			options: chartOptions,
		});
	}, []);

	return (
		<div>
			<canvas
				ref={canvasEl}
				aria-label={intl.formatMessage({ id: 'test.result.chart.label' })}
				role="img"
				style={{ height: '250px', width: '100%' }}
			>
				<Text>
					<FormattedMessage id="test.result.chart.label" />
				</Text>
			</canvas>
		</div>
	);
};

import React, { FC } from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Container } from '../ui/block/Container';
import { Text } from '../ui/typography/Text';
import { CopyUrl } from './CopyUrl';
import { RandomTip } from './RandomTip';
import { Spinner } from './Spinner';
import { BannerForm } from '../ui/banner/BannerForm';

export type Props = {
	complete: boolean;
	hash: boolean;
};

export const TestIncomplete: FC<Props> = ({ complete, hash }) => (
	<>
		<Container className="u-text-center">
			<div className="grid grid--center">
				<div className="grid__cell size--8-12@md">
					<Text className="u-mb0">
						<FormattedMessage id="test.running.text" />
						<CopyUrl />
					</Text>
				</div>
			</div>
			<Spinner />

			{!complete && <BannerForm complete={complete} hash={hash} />}

			<div className="grid grid--center">
				<div className="grid__cell size--6-12@md">
					<RandomTip />
				</div>
			</div>
		</Container>
	</>
);

import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React, { FC } from 'react';
import { Tooltip } from './Tooltip';
import { Text } from '../ui/typography/Text';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

export const FcpTooltip: FC = () => {
	const intl = useIntl();
	return (
		<Tooltip>
			<Text>
				<FormattedMessage
					id="dial.fcp.tooltip"
					values={{
						linkFcp: (
							<OutboundLink
								className="c-tooltip__link"
								href={intl.formatMessage({ id: 'dial.fcp.linkFcp.href' })}
							>
								<FormattedMessage id="dial.fcp.linkFcp.title" />
							</OutboundLink>
						),
						linkUxReport: (
							<a
								className="c-tooltip__link"
								href={intl.formatMessage({ id: 'dial.fcp.linkUxReport.href' })}
							>
								<FormattedMessage id="dial.fcp.linkUxReport.title" />
							</a>
						),
					}}
				/>
			</Text>
			<Text>
				<strong>
					<FormattedMessage id="dial.fcp.optimum" />
				</strong>
			</Text>
		</Tooltip>
	);
};

import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React, { FC, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { addEmail } from '../../api/addEmail';
import { Form } from '../ui/form/Form';
import { Input } from '../ui/form/Input';
import { Label } from '../ui/form/Label';
import { Message, Types as MessageTypes } from '../ui/form/Message';
import { Submit } from '../ui/form/Submit';
import { ResultContext } from '../molecules/ResultProvider';
import Email from '../../img/email.svg';

export const EmailForm: FC = () => {
	const intl = useIntl();
	const { result } = useContext(ResultContext);
	const { register, handleSubmit, errors } = useForm();
	const [submitting, setSubmitting] = useState(false);
	const [messages, setMessages] = useState([]);

	const onSubmit = async data => {
		setSubmitting(true);
		try {
			await addEmail(result.hash, data.email);
			setMessages([
				{
					type: MessageTypes.SUCCESS,
					message: intl.formatMessage({ id: 'email.successMessage' }),
				},
			]);
		} catch ({ messageId, values }) {
			setMessages([
				{
					type: MessageTypes.ERROR,
					message: intl.formatMessage({ id: messageId }, values),
				},
			]);
		}
		setSubmitting(false);
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<div className="u-mb-20 u-mb-60-lg">
				<div className="grid grid--center">
					<div className="grid__cell size--7-12@md size--5-12@lg size--4-12@xl">
						<Label for="email">
							<FormattedMessage id="email.label.complete" />
						</Label>
						<Input
							id="email"
							name="email"
							type="email"
							wrapClassName="inp-icon"
							defaultValue="@"
							ref={register({
								required: intl.formatMessage({ id: 'email.emailEmptyMessage' }),
							})}
						>
							<Email />
							{errors.email && <Message type={MessageTypes.ERROR}>{errors.email.message}</Message>}
							{messages.map((message, index) => (
								<Message key={index} type={message.type}>
									{message.message}
								</Message>
							))}
						</Input>
					</div>
					<div className="grid__cell size--auto@md inp-label__fix">
						<Submit disabled={submitting} icon={false}>
							<FormattedMessage id="email.submitButton.complete" />
						</Submit>
					</div>
				</div>
			</div>
		</Form>
	);
};

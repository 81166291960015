import React, { memo, ReactNode } from 'react';
import { Button } from '../button/Button';
import Lightning from '../../../img/lightning.svg';
import { Spinner } from '../../molecules/Spinner';

interface Props {
	children: ReactNode;
	disabled?: boolean;
	loading?: boolean;
	loadingTitle?: string;
	icon?: boolean;
}

export const Submit = memo(({ disabled, loading, loadingTitle, icon = true, children }: Props) => {
	return (
		<Button type="submit" load={true} disabled={disabled} loading={loading}>
			<span className="btn__text btn__text--before">
				<span className="item-icon">
					{icon && <Lightning />}
					{children}
				</span>
			</span>
			<span className="btn__text btn__text--after">
				<span className="item-icon">
					{icon && <Spinner />}
					{loadingTitle}
				</span>
			</span>
		</Button>
	);
});

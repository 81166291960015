import React, { FC, memo, FormEvent } from 'react';

export type Props = {
	onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
};

export const Form: FC<Props> = memo(props => {
	const { children, onSubmit } = props;

	return (
		<form className="f-std" onSubmit={onSubmit}>
			{children}
		</form>
	);
});

Form.displayName = 'Form';

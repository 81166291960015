const URL_BASE = 'https://54dg2fpvzb.execute-api.eu-west-1.amazonaws.com/dev/';
// const URL_BASE = 'http://localhost:3000/';

export const CREATE_NEW_TEST_ENDPOINT = `${URL_BASE}create-new-test`;

export const GET_TEST_RESULT_ENDPOINT = `${URL_BASE}get-response`;

export const ADD_EMAIL_ENDPOINT = `${URL_BASE}add-email`;

export const GET_ADMIN_DATA_ENDPOINT = `${URL_BASE}get-admin-data`;

export const FORCE_TEST_ENDPOINT = `${URL_BASE}force-test`;

export const UNSUBSCRIBE_ENDPOINT = `${URL_BASE}unsubscribe`;

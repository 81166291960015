import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { FC, memo } from 'react';
import Copy from '../../img/copy.svg';

export const CopyUrl: FC = memo(() => {
	const getUrl = () =>
		typeof window !== 'undefined'
			? window.location.protocol + '//' + window.location.host + window.location.pathname
			: '';

	const handleClick = () => {
		const textarea = document.createElement('textarea');
		textarea.value = getUrl();
		document.body.appendChild(textarea);
		textarea.select();
		document.execCommand('copy');
		document.body.removeChild(textarea);
	};

	return (
		<span className="c-copy-url">
			<button className="c-copy-url__button" role="button" type="button" onClick={handleClick}>
				<span className="c-copy-url__icon">
					<Copy />
				</span>
				<span className="c-copy-url__text">
					<FormattedMessage id="test.result.copy" />
				</span>
			</button>{' '}
			<a className="c-copy-url__link" href={getUrl()}>
				{getUrl()}
			</a>
		</span>
	);
});

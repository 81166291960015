import { GET_TEST_RESULT_ENDPOINT } from './config';
import { GetTestResultResponse } from './types';

export const getTestResult = async (testHash: string): Promise<GetTestResultResponse> => {
	const apiUrl = `${GET_TEST_RESULT_ENDPOINT}?hash=${encodeURIComponent(testHash)}`;

	const response = await fetch(apiUrl);

	if (!response.ok) {
		throw new Error(`Request to "${apiUrl}" failed. Status: ${response.status}.`);
	}

	return await response.json();
};

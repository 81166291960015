import React, { ButtonHTMLAttributes, FC, memo, ReactNode } from 'react';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: ReactNode;
	loading: boolean;
	load: boolean;
}

export const Button: FC<Props> = memo(props => {
	const { children, loading, load, ...rest } = props;

	return (
		<button className={`btn btn--block${loading ? ' is-loading' : ''}`} {...rest}>
			{load ? children : <span className="btn__text">{children}</span>}
		</button>
	);
});

Button.displayName = 'Button';

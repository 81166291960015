import { FormattedNumber } from 'gatsby-plugin-intl';
import React, { FC, memo } from 'react';

export type Props = {
	value: number;
	unit: string;
};

export const FormattedSeconds: FC<Props> = memo(({ value, unit }) => {
	return (
		<FormattedNumber
			value={value / 1000}
			minimumFractionDigits={2}
			maximumFractionDigits={2}
			style={unit}
			unit={`second`}
		/>
	);
});

FormattedSeconds.displayName = 'FormattedSeconds';

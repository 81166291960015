import React, { FC, memo, ReactNode } from 'react';

export enum Types {
	ERROR = 'error',
	WARNING = 'warning',
	SUCCESS = 'success',
	DEFAULT = 'default',
}

interface Props {
	type: Types;
	children: ReactNode;
}

export const Message: FC = memo(({ type, children }: Props) => (
	<span className={`inp-message inp-message--${type}`}>{children}</span>
));

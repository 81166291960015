import React, { FC, ReactNode } from 'react';

interface Props {
	side: 'front' | 'back';
	children: ReactNode;
}

export const PaperSide: FC = ({ side, children }: Props) => (
	<div className={`b-paper__${side}`}>
		<div className="b-paper__container">{children}</div>
	</div>
);

import React, { FC, useEffect, useRef, useState } from 'react';
import QuestionMark from '../../img/questionMark.svg';

export const Tooltip: FC = ({ children }) => {
	const refText = useRef<HTMLDivElement>(null);
	const [isVisible, setVisible] = useState(false);

	const calculatePosition = () => {
		const element = refText.current;
		const pointer = element.querySelector('.c-tooltip__pointer');
		element.style.left = null;
		pointer.style.left = null;
		const totalWidth = window.outerWidth;
		const elementWidth = element.offsetWidth;
		const elementXOffset = element.getBoundingClientRect().x;

		if (elementWidth + elementXOffset > totalWidth) {
			const overflow = elementWidth + elementXOffset - totalWidth + 10;
			element.style.left = `calc(50% - ${overflow}px)`;
			pointer.style.left = `calc(50% + ${overflow}px)`;
		}
	};

	const show = () => {
		calculatePosition();
		setVisible(true);
	};

	const hide = () => setVisible(false);

	useEffect(() => {
		window.addEventListener('resize', calculatePosition);
		return () => window.removeEventListener('resize', calculatePosition);
	}, []);

	return (
		<div className={`c-tooltip${isVisible ? ' c-tooltip--visible' : ''}`} onMouseOver={show} onMouseOut={hide}>
			<span className="c-tooltip__icon">
				<QuestionMark />
			</span>
			<div className="c-tooltip__text" ref={refText}>
				<span className="c-tooltip__pointer" />
				{children}
			</div>
		</div>
	);
};

import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React, { FC } from 'react';
import { Tooltip } from './Tooltip';
import { Text } from '../ui/typography/Text';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

export const FidTooltip: FC = () => {
	const intl = useIntl();
	return (
		<Tooltip>
			<Text>
				<FormattedMessage
					id="dial.fid.tooltip"
					values={{
						linkFid: (
							<OutboundLink
								className="c-tooltip__link"
								href={intl.formatMessage({ id: 'dial.fid.linkFid.href' })}
							>
								<FormattedMessage id="dial.fid.linkFid.title" />
							</OutboundLink>
						),
						linkUxReport: (
							<OutboundLink
								className="c-tooltip__link"
								href={intl.formatMessage({ id: 'dial.fid.linkUxReport.href' })}
							>
								<FormattedMessage id="dial.fid.linkUxReport.title" />
							</OutboundLink>
						),
					}}
				/>
			</Text>
			<Text>
				<strong>
					<FormattedMessage id="dial.fid.optimum" />
				</strong>
			</Text>
		</Tooltip>
	);
};

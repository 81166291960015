import { LoadingExperienceCategory } from '../api/types';

const category = {
	SLOW: 'error',
	AVERAGE: 'warning',
	FAST: 'success',
};


export const getCategoryColor = (param: string|number) => {

	if (typeof param === 'number') {
		param = (param >= 0.9 ? LoadingExperienceCategory.FAST : (param >= 0.5 ? LoadingExperienceCategory.AVERAGE : LoadingExperienceCategory.SLOW));
	}

	return category[param];
};
import React, { FC, useContext } from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';
import { Dials } from '../organisms/Dials';
import { LineChart } from '../organisms/LineChart';
import { TestResultTable } from '../organisms/TestResultTable';
import { Container } from '../ui/block/Container';
import { Text } from '../ui/typography/Text';
import { CopyUrl } from './CopyUrl';
import { ResultContext } from './ResultProvider';
import Twitter from '../../img/twitter.svg';
import Facebook from '../../img/facebook.svg';
import Email from '../../img/email.svg';
import LinkedIn from '../../img/linkedIn.svg';
import { Spinner } from './Spinner';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

export type Props = {
	navigation: boolean;
};

export const TestComplete: FC<Props> = ({ navigation }) => {
	const { result } = useContext(ResultContext);
	const mainPlatform = 'mobile';

	let showChart = false;

	for (const item in result[mainPlatform]) {
		if (result[mainPlatform][item].length > 3) {
			showChart = true;
		}
	}

	const mainUrlResults = result.complete ? result[mainPlatform][Object.keys(result[mainPlatform])[0]] : [];

	const getUrl = () =>
		typeof window !== 'undefined'
			? window.location.protocol + '//' + window.location.host + window.location.pathname
			: '';

	return (
		<>
			{result.complete && mainUrlResults ? (
				<>
					<Dials result={mainUrlResults} />
					<TestResultTable results={result[mainPlatform]} />
					{showChart ? (
						<LineChart results={result[mainPlatform]} />
					) : (
						<Text className="u-text-center u-pt-50 u-mb-50">
							<FormattedMessage id="test.result.chart.notEnoughData" />
						</Text>
					)}

					<Container className="u-text-center">
						<CopyUrl />
						<p>
							<span className="grid grid--center">
								<span className="grid__cell size--auto">
									<OutboundLink
										href={`https://www.facebook.com/dialog/share?app_id=10150089895695224&display=popup&href=${getUrl()}`}
										className="btn btn--facebook"
									>
										<span className="btn__text">
											<span className="item-icon">
												<Facebook />
												Sdílet na Facebook
											</span>
										</span>
									</OutboundLink>
								</span>
								<span className="grid__cell size--auto">
									<OutboundLink
										href={`https://twitter.com/intent/tweet?text=Výsledek mého testu na PageSpeed.cz – ${getUrl()}`}
										className="btn btn--twitter"
									>
										<span className="btn__text">
											<span className="item-icon">
												<Twitter />
												Sdílet na Twitter
											</span>
										</span>
									</OutboundLink>
								</span>
								<span className="grid__cell size--auto">
									<OutboundLink
										href={`https://www.linkedin.com/sharing/share-offsite/?url=${getUrl()}`}
										className="btn btn--linkedin"
										target="_blank"
									>
										<span className="btn__text">
											<span className="item-icon">
												<LinkedIn />
												Sdílet na LinkedIn
											</span>
										</span>
									</OutboundLink>
								</span>
							</span>
						</p>
						<p>
							<a href="#email-report" className="btn">
								<span className="btn__text">
									<span className="item-icon">
										<Email />
										Týdenní reporty e-mailem
									</span>
								</span>
							</a>
						</p>
						<p className="u-font-italic">
							Zajíma vás více o způsobu měření? Projděte si <Link to="/faq/">často kladené otázky</Link>.
						</p>
					</Container>
				</>
			) : (
				!navigation && <Spinner />
			)}
		</>
	);
};

import React, { FC, ReactNode } from 'react';
import { FormattedMessage, FormattedNumber } from 'gatsby-plugin-intl';

interface Props {
	value: number;
	unit: string;
	size: 'big' | 'small';
	title: string;
	tooltip: ReactNode;
	status?: string;
}

export const Dial: FC<Props> = ({ value, unit, size, title, tooltip, status }) => {
	const circumference = 100;
	const radius = circumference / (2 * Math.PI);
	const diameter = radius * 2;
	const strokeWidth = 2;
	const center = (diameter + strokeWidth) / 2;

	let textvalue;
	if (unit == '%') {
		textvalue = <FormattedNumber value={value * 100} minimumFractionDigits={0} maximumFractionDigits={0} />;
	} else {
		textvalue = <FormattedNumber value={value / 1000} minimumFractionDigits={2} maximumFractionDigits={2} />;
	}

	return (
		<div className="c-dial__container">
			<svg
				className={`c-dial c-dial--${size} c-dial--${status}`}
				viewBox={`0 0 ${diameter + strokeWidth} ${diameter + strokeWidth}`}
			>
				<circle
					className="c-dial__line"
					cx={center}
					cy={center}
					r={radius}
					strokeDasharray={`${Math.round(value * 100)}, ${circumference}`}
					strokeWidth={strokeWidth}
				/>
				<circle className="c-dial__inside" cx={center} cy={center} r={radius - strokeWidth / 2} />
				<text className="c-dial__text" x={center} y={center} dy="0.3em" textAnchor="middle">
					{textvalue}
				</text>
				<text className="c-dial__unit" x={center} y={center} dy="1.6em" textAnchor="middle">
					{unit}
				</text>
			</svg>
			<div>
				<span className="c-dial__title">
					{title}
					<FormattedMessage id={`test.status.${status}`} />
				</span>
				{tooltip}
			</div>
		</div>
	);
};

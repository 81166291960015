import React, { FC, memo, useContext, useEffect } from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { getTestResult } from '../../api/getTestResult';
import { ResultActionTypes } from '../../utils/resultReducer';
import { PaperSide } from '../molecules/PaperSide';
import { ResultContext } from '../molecules/ResultProvider';
import { TestComplete } from '../molecules/TestComplete';
import { TestIncomplete } from '../molecules/TestIncomplete';
import { Container } from '../ui/block/Container';
import { BannerForm } from '../ui/banner/BannerForm';
import loadable from '@loadable/component';

const Paper = loadable(() => import('../layout/Paper'));

export type Props = {
	hash: string;
	navigation: boolean; // Indicates if I got to this page by using form, or I loaded it separately
};

const getDelayByCount = (count: number) => (count > 10 ? 300e3 : 10e3);

export const TestResult: FC<Props> = memo(({ hash, navigation }) => {
	const { result, dispatch } = useContext(ResultContext);
	let apiCounter = 0;

	useEffect(() => {
		const run = async () => {
			const currentResult = await getTestResult(hash);
			apiCounter++;
			if (!currentResult.complete) {
				setTimeout(run, getDelayByCount(apiCounter));
			} else {
				window.history.replaceState('', null); //reset indicator - page opened from form
			}
			dispatch({
				type: ResultActionTypes.SET,
				payload: currentResult,
			});
		};

		if (hash && !result.complete) {
			run();
		}
	}, [hash]);

	let mainUrlKey;
	let mainUrlResult;
	let mainUrlResultText = <FormattedMessage id="test.done.subtitle" />;

	const speedtext = {
		FAST: 'rychlý',
		AVERAGE: 'průměrný',
		SLOW: 'pomalý',
	};

	if (result.complete) {
		console.log(Object.keys(result['mobile'])[0]);

		mainUrlKey = Object.keys(result['mobile'])[0];
		mainUrlResult = result['mobile'][mainUrlKey][0]?.loadingExperience?.overall_category;

		if (mainUrlResult) {
			if (mainUrlResult == 'FAST') {
				mainUrlResultText = (
					<>
						Gratulujeme, váš web je <span className="u-color-fast">rychlý jako blesk!</span>
					</>
				);
			} else if (mainUrlResult == 'AVERAGE') {
				mainUrlResultText = (
					<>
						Váš web <span className="u-color-average">není v dobré kondici</span>.
					</>
				);
			} else if (mainUrlResult == 'SLOW') {
				mainUrlResultText = (
					<>
						Váš web je <span className="u-color-slow">pomalý jako šnek</span>.
					</>
				);
			}
		}
	}

	return (
		<>
			{navigation ? (
				<>
					<Paper className="b-paper--flippable" flip={result.complete}>
						<PaperSide side="front">
							<Container className="u-text-center">
								<h2>
									<FormattedMessage id={navigation ? 'test.running.title' : 'test.loading.title'} />
								</h2>
							</Container>
							<TestIncomplete complete={result.complete} hash={hash} />
						</PaperSide>
						<PaperSide side="back">
							<Container className="u-text-center">
								<h2 className="u-color-blue">
									<FormattedMessage id={navigation ? 'test.done.title' : 'test.loaded.title'} />
									<small className="u-block">
										<FormattedMessage id="test.done.subtitle" />
									</small>
								</h2>
							</Container>
							<TestComplete navigation={navigation} />
						</PaperSide>
					</Paper>
					{result.complete && <BannerForm complete={result.complete} hash={hash} />}
				</>
			) : (
				<Paper className="b-paper">
					<Container className="u-text-center">
						<h2 className="u-color-blue">
							<FormattedMessage id={navigation ? 'test.done.title' : 'test.loaded.title'} />
							<small className="u-block">{mainUrlResultText}</small>
						</h2>
					</Container>
					<TestComplete navigation={navigation} />
				</Paper>
			)}
			;
		</>
	);
});

TestResult.displayName = 'TestResult';

import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'gatsby-plugin-intl';
import React, { FC } from 'react';
import { LoadingExperienceCategory, Measurement } from '../../api/types';
import { colorMap } from '../../utils/colorMap';
import { FormattedPercent } from '../molecules/FormattedPercent';
import { FormattedSeconds } from '../molecules/FormattedSeconds';
import { SpeedIndicator } from '../molecules/SpeedIndicator';
import { Url } from '../molecules/Url';
import { Tooltip } from '../molecules/Tooltip';
import { Text } from '../ui/typography/Text';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

interface Props {
	results: object;
}

export const TestResultTable: FC<Props> = ({ results }) => {
	const intl = useIntl();

	return (
		<table className="c-result-table">
			<thead className="c-result-table__head">
				<tr className="c-result-table__head__row">
					<th>
						<FormattedMessage id="test.result.table.title" />
					</th>
					<th>
						<FormattedMessage id="test.result.table.lps" />
					</th>
					<th>
						<FormattedMessage id="test.result.table.fcp" />
					</th>
					<th>
						<FormattedMessage id="test.result.table.fid" />
					</th>
				</tr>
			</thead>
			<tbody>
				{Object.keys(results).map((url, index) => {
					const measurements = results[url];
					const latest: Measurement = measurements && measurements.length ? measurements[0] : {};
					const hasLoadingExperience = latest.loadingExperience && latest.loadingExperience.metrics;

					return (
						<tr key={index} className="c-result-table__row">
							<th data-label={intl.formatMessage({ id: 'test.result.table.title' })}>
								<div className="c-result-table__url-fix">
									<Url color={colorMap[index]} url={url} main={index === 0} />
									{latest.date ? (
										<Tooltip>
											<Text className="u-font-sm">
												{'Čas testu: '}
												<FormattedDate value={latest.date} />{' '}
												<FormattedTime value={latest.date} />
												<br />
												{'URL: '}
												<OutboundLink
													href={latest.finalUrl ? latest.finalUrl : url}
													target="_blank"
												>
													{latest.finalUrl ? latest.finalUrl : url}
												</OutboundLink>
											</Text>
										</Tooltip>
									) : (
										''
									)}
								</div>
							</th>

							{latest.score ? (
								<>
									<td
										data-label={intl.formatMessage({ id: 'test.result.table.lps' })}
										className="c-result-table__score c-result-table__score--primary"
									>
										{'score' in latest ? (
											<>
												<SpeedIndicator
													category={
														latest.score >= 0.9
															? LoadingExperienceCategory.FAST
															: latest.score >= 0.5
															? LoadingExperienceCategory.AVERAGE
															: LoadingExperienceCategory.SLOW
													}
												/>
												<FormattedPercent value={latest.score * 100} fractionDigits={0} />
											</>
										) : (
											'-'
										)}
									</td>
									<td
										data-label={intl.formatMessage({ id: 'test.result.table.fcp' })}
										className="c-result-table__score c-result-table__score--secondary"
									>
										{hasLoadingExperience ? (
											<>
												<SpeedIndicator
													category={
														LoadingExperienceCategory[
															latest.loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS
																.category
														]
													}
												/>
												<FormattedSeconds
													value={
														latest.loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS
															.percentile
													}
													unit="unit"
												/>
											</>
										) : (
											'-'
										)}
									</td>
									<td
										data-label={intl.formatMessage({ id: 'test.result.table.fid' })}
										className="c-result-table__score c-result-table__score--secondary"
									>
										{hasLoadingExperience ? (
											<>
												<SpeedIndicator
													category={
														LoadingExperienceCategory[
															latest.loadingExperience.metrics.FIRST_INPUT_DELAY_MS
																.category
														]
													}
												/>
												<FormattedSeconds
													value={
														latest.loadingExperience.metrics.FIRST_INPUT_DELAY_MS.percentile
													}
													unit="unit"
												/>
											</>
										) : (
											'-'
										)}
									</td>
								</>
							) : (
								<td colSpan="3">
									Zadanou adresu se nepodařilo otestovat.
									{latest.error && (
										<Tooltip>
											<Text className="u-font-sm">
												{'Error ' + latest.error.code + ': ' + latest.error.message}
											</Text>
										</Tooltip>
									)}
								</td>
							)}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React, { FC, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { addEmail } from '../../api/addEmail';
import { ResultContext } from '../molecules/ResultProvider';
import { Form } from '../ui/form/Form';
import { Input } from '../ui/form/Input';
import { Label } from '../ui/form/Label';
import { Message, Types as MessageTypes } from '../ui/form/Message';
import { Submit } from '../ui/form/Submit';
import Email from '../../img/email.svg';

export type Props = {
	hash: string;
};

export const IncompleteEmailForm: FC<Props> = ({ hash }) => {
	const intl = useIntl();
	const { result } = useContext(ResultContext);
	const { register, handleSubmit, errors } = useForm();
	const [submitting, setSubmitting] = useState(false);
	const [messages, setMessages] = useState([
		{
			type: MessageTypes.DEFAULT,
			message: intl.formatMessage({ id: 'email.defaultMessage' }),
		},
	]);

	const onSubmit = async data => {
		setSubmitting(true);
		try {
			await addEmail(hash, data.email, true);
			setMessages([
				{
					type: MessageTypes.SUCCESS,
					message: intl.formatMessage({ id: 'email.successMessage' }),
				},
			]);
		} catch ({ messageId, values }) {
			setMessages([
				{
					type: MessageTypes.ERROR,
					message: intl.formatMessage({ id: messageId }, values),
				},
			]);
		}
		setSubmitting(false);
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid">
				<div className="grid__cell size--autogrow@md u-text-left">
					<Label for="incomplete-email">
						<FormattedMessage id="email.label.incomplete" />
					</Label>
					<Input
						id="incomplete-email"
						name="email"
						type="email"
						wrapClassName="inp-icon"
						defaultValue="@"
						ref={register({
							required: intl.formatMessage({ id: 'email.emailEmptyMessage' }),
						})}
					>
						<Email />
						{errors.email && <Message type={MessageTypes.ERROR}>{errors.email.message}</Message>}
						{messages.map((message, index) => (
							<Message key={index} type={message.type}>
								{message.message}
							</Message>
						))}
					</Input>
				</div>
				<div className="grid__cell size--auto@md inp-label__fix">
					<Submit disabled={submitting}>
						<FormattedMessage id="email.submitButton.incomplete" />
					</Submit>
				</div>
			</div>
		</Form>
	);
};
